<template>
  <b-card-body>
    <b-row>
      <b-col cols="12" md="4" class="mb-md-0 mb-2">
        <label>Ruolo</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="roleFilter"
          :options="roleOptions"
          class="w-100"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:roleFilter', val)"
        />
      </b-col>
    </b-row>
  </b-card-body>
</template>

<script>
import { BCardBody, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    vSelect,
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
