import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchWorktimes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/work-times', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchWorktime(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/work-times/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateWorktime(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/work-times/${id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addWorktime(ctx, vehicleData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/work-times', vehicleData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteWorktime(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/work-times/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
