import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import timeGridPlugin from '@fullcalendar/timegrid'
import itLocale from '@fullcalendar/core/locales/it'
import moment from 'moment'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useVehiclesList() {
  // Use toast
  const toast = useToast()

  const refWorktimesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'utente', sortable: true },
    { key: 'ruolo', sortable: true },
    { key: 'riepilogo_settimanale', sortable: false },
    { key: 'dettaglio' },
  ]
  const fields = {
    id: 'anagrafico_id',
    utente: 'display_name',
    ruolo: 'role_code',
    riepilogo_settimanale: 'week_worked_minutes',
  }

  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const workTimes = ref(null)
  const refCalendar = ref(null)
  const userId = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refWorktimesListTable.value ? refWorktimesListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refWorktimesListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchUsers', {
        text_search: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
        role_code: roleFilter.value,
        with_worked_minutes: true,
        enable_work_time_logs: 1,
      })
      .then(response => {
        const users = response.data[0]
        const total = response.data[1]

        callback(users)
        totalUsers.value = total
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  const fetchWorktimes = (ctx, successCallback) => {
    if (!userId.value) {
      return toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Errore Caricamento',
          icon: 'AlertTriangleIcon',
          text: 'Errore caricamento timbrature, riprovare!',
          variant: 'danger',
        },
      })
    }
    const startWeek = moment(ctx.start).format('YYYY-MM-DD HH:mm:ss')
    const endWeek = moment(ctx.end).format('YYYY-MM-DD HH:mm:ss')
    store
      .dispatch('app-worktimes/fetchWorktimes', {
        user_id: userId.value,
        from_start_at: startWeek,
        to_start_at: endWeek,
        all_users: 1,
      })
      .then(response => {
        const newEvents = []
        response.data[0].forEach(event => {
          let start = null
          let end = null
          let days = 0
          if (event.start_at) start = moment(event.start_at).toISOString()
          if (event.end_at) end = moment(event.end_at).toISOString()
          if (event.start_at && event.end_at) days = moment.duration(moment(end).diff(moment(start))).asDays() + 1

          if (days > 0) {
            const newEvent = {
              id: event.id,
              allDay: false,
              start,
              end,
              title: '',
              url: '',
              extendedProps: {
                calendar: 'Generale',
              },
            }
            newEvents.push(newEvent)
          }
        })
        successCallback(newEvents)
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleName = role => {
    if (role === 'user') return 'Utente'
    if (role === 'hr') return 'HR'
    if (role === 'reception_hub') return 'Reception HUB'
    if (role === 'reception_colle') return 'Reception COLLE'
    if (role === 'accountant') return 'Contabilità'
    if (role === 'admin') return 'Admin'
    return ''
  }

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'info'
    if (role === 'hr') return 'warning'
    if (role === 'reception_hub') return 'warning'
    if (role === 'reception_colle') return 'warning'
    if (role === 'accountant') return 'warning'
    if (role === 'admin') return 'danger'
    return 'danger'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'user') return 'UserIcon'
    if (role === 'hr') return 'UsersIcon'
    if (role === 'reception_hub') return 'UsersIcon'
    if (role === 'reception_colle') return 'UsersIcon'
    if (role === 'accountant') return 'UsersIcon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserXIcon'
  }

  const parseWorkedTime = minutes => {
    let h = Math.floor(minutes / 60)
    let m = minutes % 60
    h = h < 10 ? `0${h}` : h
    m = m < 10 ? `0${m}` : m
    return `${h}:${m}`
  }

  const calendarsColor = {
    Generale: 'success',
    Veicoli: 'warning',
    Riunioni: 'danger',
    Giustificativi: 'info',
  }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [timeGridPlugin],
    initialView: 'timeGridWeek',
    headerToolbar: {
      start: 'prev,next, title',
    },
    locales: [itLocale],
    locale: 'it',
    events: fetchWorktimes,

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: false,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 2,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: false,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })

  return {
    fetchUsers,
    fetchWorktimes,
    workTimes,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refWorktimesListTable,
    refCalendar,
    calendarsColor,
    calendarOptions,
    userId,

    resolveUserRoleName,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    parseWorkedTime,
    refetchData,

    // Extra Filters
    roleFilter,
  }
}
