<template>
  <div>
    <b-card class="text-center">
      <h1><feather-icon
        class="mr-2"
        icon="ClockIcon"
        size="25"
      />Timbrature</h1>
    </b-card>
    <!-- Filters -->
    <b-card
      no-body
      class="mb-0"
    >
      <worktimes-list-filters
        :role-filter.sync="roleFilter"
        :role-options="roleOptions"
      />

      <!-- Table Container Card -->

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Cerca..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refWorktimesListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessuna Timbratura corrispondente trovata"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: ID -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">#{{ data.item.anagrafico_id }}</span>
          </div>
        </template>

        <!-- Column: User -->
        <template #cell(utente)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="50"
                :src="data.item.avatar_file ? mediaUrl + data.item.avatar_file : ''"
                :text="avatarText(data.item.display_name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role_code)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.display_name }}
            </b-link>
            <small class="text-muted">@{{ data.item.identifier }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(ruolo)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role_code)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role_code)}`"
            />
            <span class="align-text-top text-capitalize">{{ resolveUserRoleName(data.item.role_code) }}</span>
          </div>
        </template>

        <!-- Column: Lavorate -->
        <template #cell(riepilogo_settimanale)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="ClockIcon"
              class="mr-1 text-primary"
              size="18"
            />
            <span class="align-text-top">{{ parseWorkedTime(data.item.week_worked_minutes) }} h</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(dettaglio)="row">
          <div class="text-center">
            <b-button
              v-if="!row.detailsShowing"
              pill
              size="sm"
              variant="primary"
              @click="
                userId = row.item.id
                fetchWorktimes(row.item.id)
                row.toggleDetails()
              "
            >
              <feather-icon
                icon="ChevronDownIcon"
                size="30"
              />
            </b-button>
            <b-button
              v-if="row.detailsShowing"
              variant="primary"
              size="sm"
              pill
              @click="row.toggleDetails"
            >
              <feather-icon
                icon="ChevronUpIcon"
                size="30"
              />
            </b-button>
          </div>
        </template>

        <template #row-details="row">
          <div class="app-calendar overflow-hidden border">
            <div class="row no-gutters">
              <div class="col position-relative">
                <div class="card shadow-none border-0 m-0 rounded-0">
                  <div class="card-body p-0">
                    <full-calendar
                      ref="refCalendar"
                      :options="calendarOptions"
                      class="full-calendar"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import userStoreModule from '@/views/apps/user/userStoreModule'
import WorktimesListFilters from './WorktimesListFilters.vue'
import useWorktimesList from './useWorktimesList'
import worktimesStoreModule from '../worktimesStoreModule'

export default {
  components: {
    FullCalendar,
    WorktimesListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const WORKTIMES_APP_STORE_MODULE_NAME = 'app-worktimes'
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(WORKTIMES_APP_STORE_MODULE_NAME)) { store.registerModule(WORKTIMES_APP_STORE_MODULE_NAME, worktimesStoreModule) }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORKTIMES_APP_STORE_MODULE_NAME)) store.unregisterModule(WORKTIMES_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'HR', value: 'hr' },
      { label: 'Reception HUB', value: 'reception_hub' },
      { label: 'Reception COLLE', value: 'reception_colle' },
      { label: 'Contabilità', value: 'accountant' },
      { label: 'Utente', value: 'user' },
    ]

    const mediaUrl = process.env.VUE_APP_MEDIA_URL

    const {
      fetchUsers,
      fetchWorktimes,
      workTimes,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWorktimesListTable,
      refCalendar,
      calendarOptions,
      userId,
      refetchData,

      // UI
      resolveUserRoleName,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      parseWorkedTime,

      // Extra Filters
      roleFilter,
    } = useWorktimesList()

    return {
      fetchUsers,
      fetchWorktimes,
      refCalendar,
      workTimes,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWorktimesListTable,
      calendarOptions,
      userId,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleName,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      parseWorkedTime,

      roleOptions,
      mediaUrl,

      // Extra Filters
      roleFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/apps/calendar-worktimes.scss';
</style>
